import React from "react";
import { Link } from "react-router-dom";

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>سرویس‌های برتر</h6>
                <h2 className='title'>
                   تیم اختصاصی <span>اقساط موبایل</span> همراه شما در هر قدم
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/4.png' alt='img' />
                </div>
                <div className='details'>
                  <div className='icon mb-3'>
                    <img src='assets/img/service/8.png' alt='img' />
                  </div>
                  <h5>
                    <div >سادگی فرآیند خرید</div>
                  </h5>
                  <p>کاربران می‌توانند به راحتی گوشی مورد نظر خود را انتخاب کنند و فرآیند اقساطی را بدون دردسر طی کنند</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/5.png' alt='img' />
                </div>
                <div className='details'>
                  <div className='icon mb-3'>
                    <img src='assets/img/service/9.png' alt='img' />
                  </div>
                  <h5>
                    <div>تضمین کیفیت</div>
                  </h5>
                  <p>کاربران می‌توانند با اطمینان از خرید موبایل‌های اقساطی با کیفیت و به روز بهره‌مند شوند و اعتماد خود را به سایت افزایش دهند</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-2 text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/6.png' alt='img' />
                </div>
                <div className='details'>
                  <div className='icon mb-3'>
                    <img src='assets/img/service/10.png' alt='img' />
                  </div>
                  <h5>
                    <div>پشتیبانی و خدمات پس از فروش</div>
                  </h5>
                  <p>کاربران می‌توانند در صورت نیاز به راهنمایی یا رفع مشکلات مرتبط با محصولات اقساطی خود با تیم پشتیبانی ارتباط برقرار کنند، که این ویژگی به تجربه کاربری مثبت افزوده می‌شود</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
