import React from "react";

const ContactAreaTwo = () => {
  return (
    <>
      {/* ========================= contact Area Two start =========================*/}
      <div className='contact-area'>
        <div className='container'>
          <div className='contact-inner-1'>
            <div className='row'>
              <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='assets/img/bg/9.jpg' alt='img' />
              </div>
              <div
                className='col-lg-4 '
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>با ما در ارتباط باشید </h6>
                  <h2 className='title'>ارتباط با واحد مشتریان سایت</h2>
                  <p className='content'>
                  شما می‌توانید از طریق فرم تماس، تلفن، یا ایمیل با ما در تماس باشید. تیم پشتیبانی ما همیشه آماده است تا به شما در هر مسئله‌ای کمک کند و سوالات شما را پاسخ دهد.                   
                  </p>
                  <div
                    className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                    id='accordionExample'
                  >
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button '
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'
                        >
                          پروسه‌ی خرید اقساطی چگونه در این سایت انجام می‌شود؟
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show text-right'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          در سایت "اقساط موبایل"، پروسه خرید اقساطی بسیار ساده است. شما می‌توانید از بین محصولات ما گوشی موبایل مورد نظر خود را انتخاب کنید و سپس طرح اقساطی مورد نظر خود را انتخاب کرده و فرم درخواست را پر کنید. تیم ما پس از بررسی درخواست شما با شما تماس خواهد گرفت تا فرآیند خرید را به شما توضیح دهد.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed '
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                          آیا سایت "اقساط موبایل" تضمین کیفیت محصولات ارائه شده را دارد؟
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          بله، در سایت "اقساط موبایل" ما تضمین کیفیت برای محصولات ارائه شده داریم. ما تنها با برندهای معتبر و محصولات اصلی همکاری می‌کنیم تا به مشتریان خود اطمینان دهیم که محصولاتی با کیفیت و اصلی را در اختیار دارند
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThree'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseThree'
                          aria-expanded='false'
                          aria-controls='collapseThree'
                        >
                          آیا پشتیبانی مشتریان در سایت "اقساط موبایل" در دسترس است؟
                        </button>
                      </h2>
                      <div
                        id='collapseThree'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          بله، ما دارای تیم پشتیبانی مشتریان حرفه‌ای هستیم که در طول روز در دسترس شما هستند. شما می‌توانید با تیم پشتیبانی ما تماس بگیرید تا به پرسش‌ها و مشکلات خود پاسخ دهید و همچنین راهنمایی لازم را در مورد فرآیند خرید و اقساط بگیرید.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Two end =========================*/}
    </>
  );
};

export default ContactAreaTwo;
