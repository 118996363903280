import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>روند کاری ما</h6>
            <h2 className='title'>
              مراحل <span>خرید</span> از سایت ما
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/9.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>مرحله۰۱</p>
                  <h5 className='mb-3'>مشاهده محصولات ما</h5>
                  <p className='content'>
                  این بخش به شما این امکان را می‌دهد که محصولات متنوع اقساطی ما را مشاهده و برای خرید انتخاب کنید.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/10.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>مرحله ۰۲</p>
                  <h5 className='mb-3'>تایید و پرداخت</h5>
                  <p className='content'>
                  شما اطلاعات خود را بررسی کرده و مبلغ مورد نظر را پرداخت می‌کنید تا سفارش شما تایید شود و محصول به شما ارسال شود.

                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/11.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>مرحله ۰۳</p>
                  <h5 className='mb-3'>تکمیل فرم درخواست</h5>
                  <p className='content'>
                  پس از انتخاب محصول و طرح اقساطی، شما باید فرم درخواست خود را تکمیل کنید تا پروسه خرید آغاز شود
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/12.svg' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>مرحله ۰۴</p>
                  <h5 className='mb-3'>انتخاب طرح اقساطی</h5>
                  <p className='content'>
                  با انتخاب یکی از طرح‌های اقساطی ما، می‌توانید شرایط مناسبی برای پرداخت اقساط موبایل خود انتخاب کنید.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
