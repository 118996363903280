import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>درباره ما</h6>
                <h2 className='title'>
                  مشاوره تخصصی برای انتخاب مناسب موبایل
                </h2>
                <p className='content mb-4'>
                اطلاعات مفید و به‌روزی در مورد گوشی‌های هوشمند، تبلت‌ها و اپلیکیشن‌های موبایل برای شما ارائه دهیم
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> فروش و توزیع موبایل‌های جدید
                      </li>
                      <li>
                        <FaCheckCircle />خدمات پس از فروش
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> مشاوره و راهنمایی در انتخاب موبایل
                      </li>
                      <li>
                        <FaCheckCircle /> تعریف و معرفی محصولات جدید
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                  سایت اقساط موبایل به عنوان یک منبع جامع و کارآمد برای علاقه‌مندان به دنیای موبایل و تکنولوژی شناخته می‌شود. در اینجا، شما می‌توانید آخرین اخبار، بررسی‌ها، نکات و ترفندهای مرتبط با دستگاه‌های هوشمند و برنامه‌های موبایل را پیدا کنید.
                </p>
                <div className='btn btn-border-base'>
                  اطلاعات بیشتر <FaPlus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
