import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area footer-area-2 bg-gray mt-0 pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb h3'>
                  {/* <img src='assets/img/logo.png' alt='img' /> */}
                  اقساط موبایل
                </div>
                <div className='details'>
                  <p>
                   ما به شما امکان می‌دهیم تا با طرح‌های اقساطی انعطاف‌پذیر، به راحتی به محصول مورد نظرتان برسید.
                  </p>
                  <div className='subscribe mt-4'>
                    <button>
                      <FaChevronRight />
                    </button>
                    <input type='text' placeholder='ایمیل' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>سرویس‌های ما</h4>
                <ul className="text-dark">
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> آخرین اخبار تکنولوژی
                    </div>
                  </li>
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> محصولات متنوع


                    </div>
                  </li>
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> خدمات و امکانات خاص


                    </div>
                  </li>
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> بلاگ


                    </div>
                  </li>
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> تماس با ما


                    </div>
                  </li>
                  <li>
                    <div className="text-dark">
                      <FaChevronRight /> خدمات مشتریان


                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Pay links</h4>
                <ul>
                  <li>
                    <div>
                      <FaChevronRight /> Credit industrys
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaChevronRight /> Reasearch sector
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaChevronRight /> Finance Sector{" "}
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaChevronRight /> Credit industrys
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaChevronRight /> Reasearch sector
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaChevronRight /> Finance Sector
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>ارتباط با ما</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                      <FaMapMarkedAlt />
                      ایران، تهران، ونک، نبش گاندی شمالی
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt />  ۰۹۱۲۱۲۳۵۶۶۷  
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope /> itteamiran@gmail.com
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>تمامی حقوق این سایت برای الوطراحی می‌باشد</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
