import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";

const TestimonialTwo = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* =================== Testimonial Two Start ===================*/}
      <div className='testimonial-area pd-top-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-7 col-lg-9'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>نظرات مشتریان</h6>
                <h2 className='title'>
                  تجربه‌های <span>اعتماد</span> از مشتریان ما
                </h2>
              </div>
            </div>
          </div>
          <div className='testimonial-slider-2  slider-control-dots'>
            <Slider {...settings}>
              <div className='item'>
                <div className='single-testimonial-inner style-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='icon mb-2'>
                        <img src='assets/img/icon/25.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <p className='designation mb-0'>
                        بسیار خوشحالم که از سایت 'اقساط موبایل' خرید کردم. طرح اقساطی راحتی داشت و تیم پشتیبانی عالی بود. گوشی مورد نظرم را با کیفیت بالا دریافت کردم. مطمئناً به دیگران هم پیشنهاد می‌کنم.
                      </p>
                    </div>
                    <div className='col-sm-4 align-self-center text-sm-end mt-4 mt-sm-0'>
                      <div className='thumb d-inline-block'>
                        <img src='assets/img/testimonial/person90.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8 mt-4'>
                      <h5 className='mb-0'>علیرضا محمدی</h5>
                    </div>
                    <div className='col-sm-4 mt-sm-4'>
                      <div className='ratting-inner'>
                        <span>امتیاز:</span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='icon mb-2'>
                        <img src='assets/img/icon/25.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <p className='designation mb-0'>
                      تجربه‌ی من با 'اقساط موبایل' بسیار خوب بود. انتخاب طرح اقساطی برای گوشی ام به سادگی انجام شد و فرآیند خرید بسیار سریع بود. تحویل هم به موقع انجام شد. ممنونم از تیم شما!
                      </p>
                    </div>
                    <div className='col-sm-4 align-self-center text-sm-end mt-4 mt-sm-0'>
                      <div className='thumb d-inline-block'>
                        <img src='assets/img/testimonial/person90.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8 mt-4'>
                      <h5 className='mb-0'>ابوالفضل صفایی</h5>
                    </div>
                    <div className='col-sm-4 mt-sm-4'>
                      <div className='ratting-inner'>
                        <span>امتیاز:</span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='icon mb-2'>
                        <img src='assets/img/icon/25.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <p className='designation mb-0'>
                      از 'اقساط موبایل' برای خرید گوشی جدیدم بسیار راضی هستم. این سایت به من امکان داد تا به آسانی گوشی مورد نظرم را با طرح اقساطی مناسب انتخاب کنم. خدمات شما بسیار حرفه‌ای و مشتری‌مدار هستند و این امر باعث شده تا از خریدم کاملاً راضی باشم.
                      </p>
                    </div>
                    <div className='col-sm-4 align-self-center text-sm-end mt-4 mt-sm-0'>
                      <div className='thumb d-inline-block'>
                        <img src='assets/img/testimonial/person90.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8 mt-4'>
                      <h5 className='mb-0'>رضا احمدی</h5>
                    </div>
                    <div className='col-sm-4 mt-sm-4'>
                      <div className='ratting-inner'>
                        <span>امتیاز:</span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='icon mb-2'>
                        <img src='assets/img/icon/25.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <p className='designation mb-0'>
                      سایت 'اقساط موبایل' برای من یک گزینه عالی بود. من به دنبال خرید یک گوشی با اقساط مناسب بودم و اینجا یک گزینه عالی برایم فراهم کرد. خدمات پس از فروش هم بسیار حرفه‌ای بود و به سوالاتم سریعاً پاسخ دادند.
                      </p>
                    </div>
                    <div className='col-sm-4 align-self-center text-sm-end mt-4 mt-sm-0'>
                      <div className='thumb d-inline-block'>
                        <img src='assets/img/testimonial/person90.png' alt='img' />
                      </div>
                    </div>
                    <div className='col-sm-8 mt-4'>
                      <h5 className='mb-0'>سهیل رجایی</h5>
                    </div>
                    <div className='col-sm-4 mt-sm-4'>
                      <div className='ratting-inner'>
                        <span>امتیاز:</span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* =================== Testimonial Two End ===================*/}
    </>
  );
};

export default TestimonialTwo;
